<template>
  <v-app
    :id="'login' + login_custom"
    :style="`background-image: linear-gradient(${colorPrimary}, ${colorPrimary}), url('${appLoginBackground}')`"
  >
    <v-main>
      <v-app-bar app color="primary" fixed dark clipped-left elevate-on-scroll>
        <router-link to="/" class="ml-2 mr-3 hidden-sm-and-down">
          <img v-if="!isDocline" :src="appLogoSecondary" :class="appMenuLogoClass" style="max-height: 32px" />
          <!-- <d-logo v-if="isDocline" :slogan="false" primary-color="#ffffff" secondary-color="#ffffff" height="28" /> -->
        </router-link>
      </v-app-bar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg6 class="activate-account">
            <div class="text-center bg-gradient">
              <v-card v-if="!accountSelector" class="elevation-1 pa-3 card-activate-account">
                <v-card-text>
                  <div
                    v-if="
                      nombreEntorno === 'doctorclic' ||
                        nombreEntorno === 'dingdoc' ||
                        nombreEntorno === 'medyola' ||
                        nombreEntorno === 'iconnecta' ||
                        nombreEntorno === 'ev2x'
                    "
                    class="layout column align-center"
                  >
                    <img :src="img_entorno" :alt="nombreEntorno" width="80%" style="margin-bottom: 40px" />
                  </div>
                  <v-form ref="activatePatientForm">
                    <h2 class="headline mb-4">{{ $t('message.welcome', { name: appDisplayName }) }}</h2>
                    <p class="mb-2 text-left">{{ $t('message.introduzca_password') }}:</p>
                    <v-text-field
                      v-model="model.password"
                      :rules="passwordRules"
                      append-icon="mdi-lock"
                      name="password"
                      :label="$t('message.password_new')"
                      type="password"
                    />
                    <v-text-field
                      v-model="model.password_reset"
                      :rules="passwordResetRules"
                      append-icon="mdi-lock"
                      name="password-repeat"
                      :label="$t('user.password_repeat')"
                      type="password"
                    />
                    <v-layout v-if="generalConditionsLabel !== ''" align-center>
                      <v-flex xs1 sm1 lg1 text-right>
                        <v-checkbox v-model="acceptGeneralConditions" color="primary" />
                      </v-flex>
                      <v-flex xs11 sm11 lg11 text-left>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span color="primary" v-html="generalConditionsLabel" />
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="conditionsLabel !== ''" align-center>
                      <v-flex xs1 sm1 lg1 text-right>
                        <v-checkbox v-model="acceptConditions" color="primary" />
                      </v-flex>
                      <v-flex xs11 sm11 lg11 text-left>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span color="primary" v-html="conditionsLabel" />
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="termAndConditionsCompany !== ''" align-center>
                      <v-flex xs1 sm1 lg1 text-right>
                        <v-checkbox v-model="acceptTermCompany" color="primary" />
                      </v-flex>
                      <v-flex xs11 sm11 lg11 text-left>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span color="primary" v-html="termAndConditionsCompany" />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    :disabled="
                      (rgpd.showOnActivation === true && (!acceptConditions || !acceptGeneralConditions)) ||
                        (termAndConditionsCompany !== '' && !acceptTermCompany)
                    "
                    :loading="loading"
                    @click="activarCuenta"
                    >{{ $t('message.activar_cuenta') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else>
                <login-account-selector :accounts="accounts" @loginCanceled="loginCanceled" />
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }} </v-icon>&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import environment from '@/environment';
import {
  nameEnv,
  activarCuentaUrl,
  clientId,
  clientSecret,
  loginUrl,
  userUrl,
  getHeader,
  displayNameEnv,
} from '../config/config';
import LoginAccountSelector from '../components/Login/LoginAccountSelector.vue';
import { homePage } from '@/router/paths';

export default {
  components: { LoginAccountSelector },
  data() {
    return {
      conditionsLabel: '',
      generalConditionsLabel: '',
      termAndConditionsCompany: '',
      rgpd: {
        urlPolicy: '',
        urlCookies: '',
        urlDataTreatment: '',
      },
      accountSelector: false,
      accounts: [],
      acceptConditions: false,
      acceptGeneralConditions: false,
      acceptTermCompany: false,
      loading: true,
      model: {
        password: '',
        password_reset: '',
        error: '',
      },
      snackbar: false,
      mostrar_reset_pw: false,
      timeout: 6000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-lock',
        color: 'info',
      },
      reset_titulo: '',
      btn_acceder_titulo: '',
      nombreEntorno: displayNameEnv,
      login_custom: '',
      img_entorno: '/static/docline_640.png',
      colorPrimary: '#1976D2eb',

      passwordRules: [
        v => !!v.trim() || this.$t('errors.password_required'),
        v => !!v.match(/[a-z]/) || this.$t('user.password_must_contain_lowercase'),
        v => !!v.match(/[A-Z]/) || this.$t('user.password_must_contain_uppercase'),
        v => !!v.match(/\d/) || this.$t('user.password_must_contain_numbers'),
        v => !!v.match(/\W/) || this.$t('user.password_must_contain_symbols'),
        v => v.trim().length >= 8 || this.$t('user.password_min_length', { num: 8 }),
      ],

      passwordResetRules: [
        v => !!v || this.$t('errors.password_required'),
        v => v.trim() === this.model.password || this.$t('user.password_no_igual'),
      ],
    };
  },

  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    ...mapState({
      appDisplayName: state => state.app.displayName,
      appLoginBackground: state => state.app.images.loginBackground,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoBackground: state => state.app.styles.menuLogoBackground,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appThemePrimary: state => state.app.styles.themePrimary,
      appThemeSecondary: state => state.app.styles.themeSecondary,
      appName: state => state.app.name,
    }),
    isDocline() {
      //TODO:Crear mejor metodo
      return /^docline/.test(this.appName);
    },
  },
  created() {
    this.rgpd = environment.footer.rgpd;
    if (this.rgpd.showOnActivation === true) {
      this.conditionsLabel = this.$t('message.acceptConditions', {
        privacyPolicyLink: this.rgpd.urlPolicy,
        legalLink: this.rgpd.urlLegal,
      });
      this.generalConditionsLabel = this.$t('message.acceptGeneralConditions', {
        generalConditionsLink: this.rgpd.urlConditions,
      });
    }

    this.colorPrimary = this.$vuetify.theme.primary + 'eb';
    if (this.nombreEntorno === 'iconnecta') {
      this.login_custom = 'Iconnecta';
      this.$vuetify.theme.primary = '#DA2B49';
      this.img_entorno =
        'https://docline-storage.ams3.digitaloceanspaces.com/empresa/logo_emails/logo-iconnecta@2x.png';
    } else if (this.nombreEntorno === 'dingdoc' || this.nombreEntorno === 'ev2x') {
      this.login_custom = 'Dingdoc';
      this.$vuetify.theme.primary = '#2a6989';
      this.img_entorno = this.nombreEntorno === 'dingdoc' ? '/static/logo_dingdoc.png' : '/static/logo_ev2x.png';
    } else if (this.nombreEntorno === 'doctorclic') {
      this.img_entorno = '/static/logo_doctorclick.png';
    } else if (this.nombreEntorno === 'medyola') {
      this.img_entorno = '/static/logo_medyola.png';
    }
    const postData = {
      accion: 'index',
      token: this.$route.params.token,
      email: this.$route.params.email,
    };
    this.$http.post(activarCuentaUrl, postData).then(response => {
      if (response.data.status === 1) {
        if (response.data.terms_conditions !== '' && response.data.terms_conditions !== null) {
          this.termAndConditionsCompany = response.data.terms_conditions;
        }
        this.loading = false;
        const intervalo = setInterval(() => {
          this.itereaciones_itercom = this.itereaciones_itercom + 1;
          const intercom = document.getElementsByClassName('intercom-launcher')[0];
          if (typeof intercom !== 'undefined' && intercom !== null) {
            intercom.style.display = 'none';
            clearInterval(intervalo);
          }
          if (this.itereaciones_itercom > 20 || (intercom && intercom.style.display === 'none')) {
            clearInterval(intervalo);
          }
        }, 1500);

        if (response.data.company.toUpperCase() === 'UNISALUD') {
          this.changeTextUnisalud();
        }
      } else {
        this.$router.push('/login');
      }
    });
  },
  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),

    volverLogin() {
      this.$router.push('/login');
    },
    encriptar(input) {
      let output = '';
      for (let i = 0; i < input.length; i++) {
        output += input.charCodeAt(i).toString(16);
      }
      return output;
    },

    load_i18n_message: function(folder) {
      const locale = this.$i18n.locale;
      const messages = this.$i18n.messages[locale];
      try {
        const trans_custom = require('@/core/i18n/' + folder + '/' + locale);
        const t = trans_custom.default;
        Object.keys(t).forEach(item => {
          if (typeof t[item] === 'object') {
            Object.keys(t[item]).forEach(trans => {
              if (messages[item]) {
                messages[item][trans] = t[item][trans];
              }
            });
          }
        });
        this.$i18n.setLocaleMessage(locale, messages);
      } catch (e) {
        this.$log.error(e);
      }
    },

    activarCuenta() {
      if (!this.loading) {
        if (!this.$refs.activatePatientForm.validate()) {
          this.alerta.texto = this.$t('user.invalid_password');
          this.alerta.color = 'error';
          this.snackbar = true;
          return;
        }

        this.loading = true;
        const postData = {
          accion: 'activarCuenta',
          email: this.$route.params.email,
          password: this.model.password,
          token: this.$route.params.token,
        };
        this.$http.post(activarCuentaUrl, postData).then(response => {
          if (response.data.status === 1) {
            this.alerta.texto = this.$t('success.account_active');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-shield-check';
            const authUser = {};
            const postData = {
              grant_type: 'password',
              client_id: clientId,
              client_secret: clientSecret,
              username: this.$route.params.email,
              password: this.model.password,
              scope: '',
            };
            const ua = navigator.userAgent;
            const isInternetExplorer = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
            if (isInternetExplorer || (!!window.MSInputMethodContext && !!document.documentMode)) {
              return this.$router.push('/login');
            }

            this.$http.post(loginUrl, postData).then(response => {
              if (response.status === 200) {
                authUser.access_token = response.data.access_token;
                authUser.refresh_token = response.data.refresh_token;
                window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
                this.$http.get(userUrl, { headers: getHeader() }).then(response => {
                  if (response.body.estado === 1) {
                    this.authUserSave(this.authUserMapper(authUser, response.body));
                    this.loadConfigFromUser();
                    if (!this.checkShowAccountSelector(response.body)) {
                      this.goToHome(response.body);
                    }
                  }
                });
              }
            });
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
          }
          this.snackbar = true;
          this.loading = false;
        });
      }
    },

    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data.empresa.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      return {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        company_docline_api_id: data?.empresa?.docline_api_id || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        is_collective: data?.empresa?.is_collective || 0,
        erecipe: !isPatient && typeof data.perfil.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        environment: data?.environment || [],
      };
    },

    checkShowAccountSelector() {
      const roles = JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
        : [];
      const roleName = [
        this.$t('common.undefined'),
        this.$t('common.admin'),
        this.$t('common.professional'),
        this.$t('common.receptionist'),
        this.$t('common.rrhh'),
        this.$t('common.patient'),
      ];
      const items = [];
      roles.forEach(function(role) {
        items.push({
          title:
            role.company_name !== ''
              ? role.company_name
              : JSON.parse(window.localStorage.getItem('auth_ehealth'))['fullName'],
          subtitle: roleName[role.role_id],
          icon: role.icon,
          id: role.id,
          roleId: role.role_id,
        });
        items.push({ divider: true });
      });
      if (items.length > 0) {
        items.pop();
      }
      this.accounts = items;
      this.accountSelector = roles.length > 1;
      return roles.length > 1;
    },

    goToHome(data) {
      let routerPath = data.user.rol === 1 && nameEnv !== 'medyola' ? { name: 'Usuarios' } : '/' + homePage();
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (isPatient && window.localStorage.getItem('pathRequiresAuth')) {
        const pathRequiresAuth = JSON.parse(window.localStorage.getItem('pathRequiresAuth'));
        const now = new Date();
        const dateRequiresAuth = new Date(pathRequiresAuth.date);
        const timeBetween = (now - dateRequiresAuth) / (1000 * 60);
        if (!isNaN(timeBetween) && timeBetween < 60) {
          routerPath = pathRequiresAuth.fullPath;
        }
        localStorage.removeItem('pathRequiresAuth');
      }
      this.$router.push(routerPath);
    },

    authUserSave(authUser) {
      window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
    },

    changeTextUnisalud() {
      this.generalConditionsLabel = this.$t('message.acceptGeneralConditions_LOPDGDD', {
        generalConditionsLink: this.rgpd.urlGeneralConditions,
      });
    },

    loginCanceled() {
      localStorage.removeItem('auth_ehealth');
      this.accounts = [];
      this.accountSelector = false;
    },
  },
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}

#loginIconnecta {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background-image: url('/static/login_bg_psicooncologia.png');
}

#loginDingdoc {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background-image: url('/static/login_bg_dingdoc.png');
}

.activate-account {
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  padding: 20px;
}

.bg-gradient {
  background: var(--v-secondary-base);
  background: linear-gradient(114deg, var(--v-primary-base) 20%, var(--v-secondary-base) 100%);
  border-radius: 10px;
  padding: 1px 30px 30px 30px !important;
  max-width: 790px;
}

.card-activate-account {
  margin-top: -32px;
}

#resetPw {
  text-decoration: none;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.v-application.ie .primary {
  background-color: #01508a !important;
  border-color: #01508a !important;
}
.v-application.ie .v-btn::before {
  background: none !important;
}
</style>
