<template>
  <v-app>
    <v-main />
  </v-app>
</template>

<script>
import { activateProfessionalAsPatient } from '../config/config';
import { baseNameRoutes } from '@/router/paths.ts';

export default {
  name: 'ActivateProfessionalAsPatient',
  computed: {},
  created() {
    try {
      const postData = {
        accion: 'activatePatient',
        token: this.$route.params.token,
        email: this.$route.params.email,
      };

      this.$http.post(activateProfessionalAsPatient, postData).then(res => {
        if (res.status === 200) {
          this.$router.push({ name: baseNameRoutes.login });
        }
      });
    } catch (e) {
      // TODO: pendiente de implementar control de errores
      console.error('error activatepac', e);
    }
  },
};
</script>
<style></style>
